export default {
    noFound: {
        clickBack: "点我返回",
        noFoundPage: "页面未找到",
    },
    global: {
        title: "智慧会议管理平台",
        gotoLogin: "返回登录",
        verifyIdentity: "验证身份",
        resetPassword: "重置密码",
        finish: "完成",
        footer: "智慧会议平板云服务软件系统 版权所有©广东保伦电子股份有限公司",
        userAgree: "《服务协议》",
        and: "和",
        privacyPolicy: "《隐私条款》",
        codeLogin: "验证码登录",
        passwordLogin: "密码登录",
        haveRead: "我已阅读并同意",
        queryRead: "请确认我已阅读并同意",
        meetingNo: "会议号",
        meetingTime: "会议时间",
        inviteYouTo: "邀请您",
        to: "进入",
    },
    axios: {
        expire: "用户身份过期，请联系管理员",
        noRegister: "手机号未注册，为您前往注册页面",
        pleaseLogin: "登录过期，请重新登录",
    },
    route: {
        home: "首页",
        userManage: "用户管理",
        meetingManage: "会议管理",
        myMeeting: "我的会议",
        createMeeting: "创建会议",
        editMeeting: "编辑会议",
        unitMeeting: "单位会议",
        historyMeeting: "历史会议",
        meetingRecord: "会议录制",
        meetingSetting: "会议设置",
        unitManage: "单位管理",
        orgStr: "组织结构",
        unitInfo: "单位信息",
        disUnit: "解散单位",
        meetingRoom: "会议室管理",
        flatPanel: "设备管理",

        persionalInfo: "个人信息", //23
        logout: "注销账号",
        exitUnit: "退出单位",
        disUser: "停用用户",
        meetingMaterials: "会议资料",
        voteScore: "投票评分",
        personalManage: "人员管理",
        signManage: "签到管理", //30

        looseUser: "游离用户",
        createUnit: "创建单位",
        createSucess: "创建成功",
        waitReview: "等待审核",
        reviewRejected: "审核拒绝",
        interface: "接口统计",
        logManage: "日志管理",
        advanced: "高级",
        versionManage: "版本管理",

        modify: "修改", //42
        add: "新增",
        meeting: "会议",
        myRecord: "我的录制",
    },
    message: {
        message1: "姓名不能为空",
        message2: "姓名中不能包含空格",
        message3: "密码不能为空",
        message4: "密码中不能包含空格", // 48
        message5: "密码限制8~16，由数字、大写字母、小写字母，特殊符号3种或3种以上组合组成",
        message6: "密码长度为8-16位",
        message7: "名称不能为空",
        message8: "名称中不能包含特殊字符",
        message9: "名称长度不能多于20位",
        message10: "手机号不能为空",
        message11: "请输入正确手机号",
        message12: "验证码不能为空",
        message13: "验证码中不能包含空格",
        message14: "验证码长度只能为6位",
        message15: "邮箱不能为空",
        message16: "请输入正确邮箱",
        message17: "地址长度不允许大于50位",
        message18: "手机号不能输入非数字内容",
        message19: "手机号格式有误",
        message20: "职务长度不能多于20位",
        message21: "只允许字母数字下划线和汉字", // 68
        message22: "您已加入组织", //74
        message23: "验证码已发送到手机",
        message24: "您的申请未通过审核，如需重新申请请点击",
        message25: "请填写您的姓名和登陆密码",
        message26: "请输入姓名",
        message27: "请检查信息是否填写正确",
        message28: "两次输入的密码不一致",

        tip1: "未注册手机号验证通过之后将自动注册",
        tip2: "请输入手机号",
        tip3: "请输入验证码",
        tip4: "请输入密码",
        tip5: "详细情况请联系管理员",
        tip6: "暂无会议名称",
    },
    clickEvent: {
        login1: "立即登录",
        register: "注册新用户",
        forgot: "忘记密码",
        getCode: "获取验证码",
        reSend: "重新发送",
        reApply: "重新申请",
        cInfo: "完善信息",
        back: "返回",
        regJoin: "注册并加入",
        accountExists: "已有帐号",
        logNow: "Log in now",
    },
    label: {
        name: "姓名",
        password: "密码",
        confirmPassword: "确认密码",
        phoneNum: "手机号",
        phoneEmail: "手机号/邮箱",
        email: "邮箱",
        code: "验证码",
    },
    all: {
        Message1: "用户身份过期，请联系管理员",
        Message2: "手机号未注册，为您前往注册页面",
        Message3: "已过期",
        Message4: "登陆过期，请重新登录",
        Message5: "用户身份已过期",
        Message6: "首页",
        Message7: "会议管理",
        Message8: "我的会议",
        Message9: "创建会议",
        Message10: "编辑会议",
        Message11: "单位会议",
        Message12: "历史会议",
        Message13: "会议录制",
        Message14: "会议设置",
        Message15: "单位管理",
        Message16: "组织结构",
        Message17: "单位信息",
        Message18: "解散单位",
        Message19: "会议室管理",
        Message20: "一体机",
        Message21: "用户管理",
        Message22: "个人信息",
        Message23: "注销账号",
        Message24: "退出单位",
        Message25: "停用用户",
        Message26: "会议资料",
        Message27: "投票评分",
        Message28: "人员管理",
        Message29: "签到管理",
        Message30: "游离用户",
        Message31: "创建单位",
        Message32: "创建成功",
        Message33: "等待审核",
        Message34: "审核拒绝",
        Message35: "会议一体机",
        Message36: "身份过期，请重新登录",
        Message37: "接口统计",
        Message38: "日志管理",
        Message39: "高级",
        Message40: "版本管理",
        Message41: "修改",
        Message42: "新增",
        Message43: "会议",
        Message44: "我的录制",
        Message45: "北京",
        Message46: "微软雅黑",
        Message47: "姓名不能为空",
        Message48: "姓名中不能包含空格",
        Message49: "密码不能为空",
        Message50: "密码中不能包含空格",
        Message51: "密码限制{0}，由数字、大写字母、小写字母，特殊符号{1}种或{2}种以上组合组成",
        Message52: "密码长度为{0}位",
        Message53: "名称不能为空",
        Message54: "名称中不能包含特殊字符",
        Message55: "名称长度不能多于",
        Message56: "手机号不能为空",
        Message57: "请输入正确手机号",
        Message58: "验证码不能为空",
        Message59: "验证码中不能包含空格",
        Message60: "验证码长度只能为",
        Message61: "邮箱不能为空",
        Message62: "请输入正确邮箱",
        Message63: "地址长度不允许大于",
        Message64: "手机号不能输入非数字内容",
        Message65: "手机号格式有误",
        Message66: "职务长度不能多于",
        Message67: "只允许字母数字下划线和汉字",
        Message68: "读取用户信息失败，请重新登录",
        Message69: "页面未找到",
        Message70: "点我返回",
        Message71: "会议管理平台",
        Message72: "您已加入组织",
        Message73: "返回登录",
        Message74: "验证身份",
        Message75: "重置密码",
        Message76: "完成",
        Message77: "版权所有",
        Message78: "广东保伦电子股份有限公司",
        Message79: "《服务协议》",
        Message80: "和",
        Message81: "《隐私条款》",
        Message82: "验证码登录",
        Message83: "密码登录",
        Message84: "未注册手机号验证通过之后将自动注册",
        Message85: "请输入手机号",

        Message86: "请输入验证码",
        Message87: "我已阅读并同意",
        Message88: "登录",
        Message89: "注册新用户",
        Message90: "忘记密码",
        Message91: "请输入密码",
        Message92: "获取验证码",
        Message93: "重新发送",
        Message94: "验证码已发送到手机",
        Message95: "您的申请未通过审核",
        Message96: "如需重新申请请点击",
        Message97: "重新申请",
        Message98: "详细情况请联系管理员",
        Message99: "暂无会议名称",
        Message100: "会议号：",
        Message101: "会议时间：",
        Message102: "完善信息",
        Message103: "请填写您的姓名和登陆密码",
        Message104: "返回",
        Message105: "姓名",
        Message106: "请输入姓名",
        Message107: "密码",
        Message108: "确认密码",
        Message109: "注册并加入",
        Message110: "请检查信息是否填写正确",
        Message111: "两次输入的密码不一致",
        Message112: "邀请您",
        Message113: "进入",
        Message114: "手机号",
        Message115: "验证码",
        Message116: "已有帐号",
        Message117: "立即登录",
        Message118: "该链接已失效，可重新复制链接进入邀请页面",
        Message119: "您已申请加入另一家单位",
        Message120: "您已加入单位，无法重复加入单位",
        Message121: "同意邀请成功，将直接为您登录",
        Message122: "下一步",
        Message123: "温馨提示：如果您的手机和邮箱均无法使用，请尽快联系企业管理员更新手机和邮箱",
        Message124: "仅中国大陆手机号可用来找回密码",
        Message125: "该手机号并未注册，您可前往注册页面完成注册",
        Message126: "新密码",
        Message127: "请确认密码",
        Message128: "温馨提示",
        Message129: "位数字、英文字母和符号",
        Message130: "两次密码输入不一致",
        Message131: "修改密码成功",
        Message132: "倒计时",
        Message133: "秒后",
        Message134: "新用户注册",
        Message135: "该手机号已经注册过，您可直接登录",
        Message136: "提交",
        Message137: "恭喜您，注册成功",
        Message138: "即将为您自动登录",
        Message139: "搜索会议名称",
        Message140: "返回首页",
        Message141: "退出登录",
        Message142: "您确定要退出登录吗？",
        Message143: "会议管理后台",
        Message144: "取消",
        Message145: "确定",
        Message146: "提示",
        Message147: "个人",
        Message148: "管理",
        Message149: "暂无相关数据",
        Message150: "序号",
        Message151: "未开始",
        Message152: "进行中",
        Message153: "已结束",
        Message154: "管理员",
        Message155: "普通用户",
        Message156: "点击上传",
        Message157: "单次最多只能上传{0}个文件；文件总数最多不能超过{1}个；文件名称不能超过{2}个字符",
        Message158: "确定移除？",
        Message159: "开始日期",
        Message160: "结束日期",
        Message161: "请选择",
        Message162: "最近一周",
        Message163: "最近一个月",
        Message164: "最近三个月",
        Message165: "全部",
        Message166: "升级程序",
        Message167: "客户端",
        Message168: "弹幕加载中",
        Message169: "顶部",
        Message170: "底部",
        Message171: "滚动",
        Message172: "输入弹幕，回车发送",
        Message173: "关于作者",
        Message174: "播放器意见反馈",
        Message175: "关于",
        Message176: "播放器",
        Message177: "循环",
        Message178: "速度",
        Message179: "弹幕透明度",
        Message180: "正常",
        Message181: "要输入弹幕内容啊喂！",
        Message182: "设置弹幕颜色",
        Message183: "设置弹幕类型",
        Message184: "显示弹幕",
        Message185: "视频加载失败",
        Message186: "弹幕加载失败",
        Message187: "弹幕发送失败",
        Message188: "正在切换至",
        Message189: "已经切换至",
        Message190: "画质",
        Message191: "快进",
        Message192: "快退",
        Message193: "海量弹幕",
        Message194: "发送弹幕",
        Message195: "设置",
        Message196: "全屏",
        Message197: "页面全屏",
        Message198: "发送",
        Message199: "截图",
        Message200: "无线投屏",
        Message201: "秒",
        Message202: "显示字幕",
        Message203: "隐藏字幕",
        Message204: "音量",
        Message205: "直播",
        Message206: "视频统计信息",
        Message207: "平均响应时间",
        Message208: "调用次数",
        Message209: "搜索",
        Message210: "名称",
        Message211: "账号已被停用，详细情况请联系单位管理人员",
        Message212: "单位资源：",
        Message213: "使用情况：",
        Message214: "每日与会人数统计",
        Message215: "每日会议时长统计",
        Message216: "每日会议数统计",
        Message217: "每日活跃用户统计",
        Message218: "最近一年",
        Message219: "当日与会人数",
        Message220: "会议室(个)",
        Message221: "用户账号",
        Message222: "今日会议次数",
        Message223: "今日会议总时长(分)",
        Message224: "今日活跃用户(个)",
        Message225: "当日会议时长",
        Message226: "当日会议数量",
        Message227: "",
        Message228: "当日活跃用户",
        Message229: "已完成申请加入{msg}，待管理员审核通过后即可使用",
        Message230: "撤销申请",
        Message231: "确定要撤销申请加入{msg}吗？",
        Message232: "您的申请已通过",
        Message233: "您的申请被拒绝",
        Message234: "审核未通过，详细情况请联系管理人员",
        Message235: "单位名称",
        Message236: "请填写单位名称",
        Message237: "请输入单位名称",
        Message238: "名称长度限制为2~20中英文",
        Message239: "中英文",
        Message240: "只允许输入中文或英文字母",
        Message241: "单位创建成功",
        Message242: "邀请同事加入",
        Message243: "进入单位",
        Message244: "您当前未创建单位，请先创建单位或加入单位",
        Message245: "数据加载中...请稍候",
        Message246: "请稍候",
        Message247: "新增节点",
        Message248: "请填写节点名称",
        Message249: "删除节点",
        Message250: "该节点下存在用户，无法删除",
        Message251: "确认要删除节点吗",
        Message252: "编辑节点",
        Message253: "节点名称不能为空",
        Message254: "节点删除成功",
        Message255: "解散单位说明",
        Message256: "解散单位是不可恢复的操作。在解散单位后，所有成员都成为独立的个人用户账号。",
        Message257: "您在解散单位前，请确认：",
        Message258: "您的单位所包含的文件已得到妥善保存：包括但不限于会议录像等。",
        Message259: "单位无任何纠纷：包括投诉举报、被投诉举报、仲裁、诉讼等纠纷。",
        Message260: "当前手机：",
        Message261: "手机验证码：",
        Message262: "获取",
        Message263: "解散单位成功",
        Message264: "重新获取",
        Message265: "请先同意解散单位说明",
        Message266: "绑定邮箱",
        Message267: "邮箱：",
        Message268: "请输入邮箱",
        Message269: "验证码：",
        Message270: "邮箱绑定成功",
        Message271: "当前邮箱地址为：",
        Message272: "该邮箱已经被注册过",
        Message273: "确认",
        Message274: "该手机号已经注册过",
        Message275: "修改成功",
        Message276: "修改邮箱",
        Message277: "邮箱：",
        Message278: "新邮箱：",
        Message279: "提示：修改密码后将自动退出登录",
        Message280: "旧密码",
        Message281: "请输入旧密码",
        Message282: "请输入新密码",
        Message283: "新密码前后输入不一致",
        Message284: "密码修改成功",
        Message285: "修改手机",
        Message286: "新手机",
        Message287: "请输入新手机号",
        Message288: "手机号修改成功",
        Message289: "手机号已修改为：",
        Message290: "单位名称：",
        Message291: "保存",
        Message292: "地址：",
        Message293: "单位：",
        Message294: "管理员账号：",
        Message295: "管理员密码：",
        Message296: "管理员手机号：",
        Message297: "绑定",
        Message298: "管理员邮箱：",
        Message299: "修改密码",
        Message300: "修改手机号",
        Message301: "绑定手机号",
        Message302: "暂无",
        Message303: "暂无地址",
        Message304: "租户信息获取错误",
        Message305: "单位名称不能为空",
        Message306: "字符",
        Message307: "只允许输入字母和汉字",
        Message308: "地址不能为空",
        Message309: "地址长度不允许超过",
        Message310: "地址不能为空！",
        Message311: "地址不允许包含空格",
        Message312: "账号",
        Message313: "操作时间",
        Message314: "所属模块",
        Message315: "操作类型",
        Message316: "操作详情",
        Message317: "查看",
        Message318: "模块名称",
        Message319: "日志导出",
        Message320: "删除",
        Message321: "操作",
        Message322: "删除日志",
        Message323: "确定要删除所选日志吗",
        Message324: "请选择删除项",
        Message325: "请选择导出项",
        Message326: "日志详情",
        Message327: "操作日志",
        Message328: "日志操作列表",
        Message329: "暂无详情",
        Message330: "删除成功",
        Message331: "会议名称",
        Message332: "预订人",
        Message333: "企业/组织",
        Message334: "组织",
        Message335: "会议号",
        Message336: "开始时间",
        Message337: "录制",
        Message338: "查看详情",
        Message339: "导出",
        Message340: "是",
        Message341: "否",
        Message342: "会议保密",
        Message343: "删除历史会议将清除会议相关数据",
        Message344: "您确定要删除所选历史会议吗",
        Message345: "会议文档",
        Message346: "文件名",
        Message347: "下载",
        Message348: "预览",
        Message349: "议程文件",
        Message350: "议题文件",
        Message351: "临时资料",
        Message352: "会议录屏",
        Message353: "会议打包文件",
        Message354: "该类型暂时无法预览",
        Message355: "时长",
        Message356: "文件大小",
        Message357: "你确定要删除录制文件吗？",
        Message358: "如果文件较大，下载需要等待较长时间，确定要下载吗",
        Message359: "无数据缺省图",
        Message360: "会议录制文件",
        Message361: "下载出错",
        Message362: "删除失败",
        Message363: "部门",
        Message364: "与会者人数",
        Message365: "状态",
        Message366: "编辑",
        Message367: "结束",
        Message368: "确定取消会议？",
        Message369: "当前会议为{0}预订",
        Message370: "发送短信通知到预订人",
        Message371: "当前会议",
        Message372: "已经通过短信方式通知所有与会者",
        Message373: "发送短信通知到所有与会者",
        Message374: "保密会议正在进行中，是否要强制结束？",
        Message375: "当前会议正在进行，会议文件请在一体机客户端进行保存",
        Message376: "可点击【确定】强制结束会议",
        Message377: "取消成功",
        Message378: "停止会议成功",
        Message379: "安全",
        Message380: "资料加密：",
        Message381: "请选择会议文件是否加密",
        Message382: "加密（会议资料只能在终端打开）",
        Message383: "不加密",
        Message384: "示意：会议资料加密上传，且只能用应用终端打开；会议普通用户可在历史会议解密导出会议资料，但不可查看详情",
        Message385: "应用终端打开；会议普通用户可在历史会议解密导出会议资料，但不可查看详情",
        Message386: "资料下载",
        Message387: "请选择会议是否保密",
        Message388: "不允许下载",
        Message389: "允许下载",
        Message390: "示意：历史会议的会议资料将允许普通用户下载",
        Message391: "留存时间",
        Message392: "请选择留存时间",
        Message393: "重置",
        Message394: "永久",
        Message395: "六个月",
        Message396: "十二个月",
        Message397: "请选择时区",
        Message398: "请选择是否加密",
        Message399: "请选择是否保密",
        Message400: "保存成功",
        Message401: "重置成功",
        Message402: "上传人",
        Message403: "上传时间",
        Message404: "会议议程",
        Message405: "对象形式",
        Message406: "黑体",
        Message407: "绝绝字体",
        Message408: "仿宋",
        Message409: "楷体",
        Message410: "标楷体",
        Message411: "华文仿宋",
        Message412: "华文楷体",
        Message413: "自定义",
        Message414: "可上传",
        Message415: "格式文档，复杂的",
        Message416: "文档需先转换",
        Message417: "格式再上传（文件支持最大{msg}）",
        Message418: "议程文件生成中...请稍后",
        Message419: "确定要删除议程文件吗？",
        Message420: "自定义议程",
        Message421: "清空",
        Message422: "自动生成",
        Message423: "暂未确定会议室地址",
        Message424: "暂无主持人信息",
        Message425: "会议时间",
        Message426: "会议地点",
        Message427: "会议主持",
        Message428: "参会人员",
        Message429: "此操作将覆盖原议程文件",
        Message430: "是否继续",
        Message431: "已取消覆盖上传",
        Message432: "该会议已结束，无法上传文件",
        Message433: "文件大小不能超过",
        Message434: "不允许上传该格式文件",
        Message435: "上传成功",
        Message436: "文件处理失败",
        Message437: "新增议题",
        Message438: "下载议题文件",
        Message439: "查看允许上传的格式",
        Message440: "搜索议题关键字",
        Message441: "议题名称",
        Message442: "创建时间",
        Message443: "议题描述",
        Message444: "相关文件",
        Message445: "删除议题同时会删除相关文件",
        Message446: "您确定要删除吗",
        Message447: "下载文件整体体积过大将会花费较长时间",
        Message448: "期间不会影响您进行其它操作",
        Message449: "您确定要下载吗",
        Message450: "允许上传的格式",
        Message451: "会议资源预览",
        Message452: "上传相关文件",
        Message453: "议题名称和描述不能为空",
        Message454: "查看议题",
        Message455: "编辑议题",
        Message456: "删除成功，如页面未更新请刷新页面",
        Message457: "单次最多上传100个文件；云盘文件总数最多不得超过200个；文件名称不得超过80个字符",
        Message458: "提示：上传的文档中带有超链接将导致文件转码失败，请删除超链接后重新上传文件",
        Message459: "格式不合法，无法上传",
        Message460: "存在转码失败的文件，为您从上传列表清除",
        Message461: "资料名称",
        Message462: "上传文件",
        Message463: "下载资料",
        Message464: "搜索资料关键字",
        Message465: "您确定要删除所选文件吗？",
        Message466: "会议文件",
        Message467: "文件大于2GB,无法上传",
        Message468: "无法上传",
        Message469: "设备型号",
        Message470: "投票标题",
        Message471: "记名方式",
        Message472: "匿名",
        Message473: "实名",
        Message474: "投票规则",
        Message475: "多选",
        Message476: "单选",
        Message477: "记票方式",
        Message478: "举手投票",
        Message479: "应用投票",
        Message480: "新增时间",
        Message481: "评分标题",
        Message482: "评分类型",
        Message483: "设备",
        Message484: "激活码",
        Message485: "离线",
        Message486: "在线",
        Message487: "开会中",
        Message488: "解绑",
        Message489: "添加",
        Message490: "会议室名称",
        Message491: "确定删除会议室？",
        Message492: "该会议室下存在一体机，请先移除一体机后再操作",
        Message493: "添加设备",
        Message494: "选择会议室",
        Message495: "请选择会议室",
        Message496: "编辑设备",
        Message497: "设备名称",
        Message498: "设备激活码",
        Message499: "确定要解除绑定吗",
        Message500: "确定删除已选设备？",
        Message501: "请输入会议室名称",
        Message502: "请输入设备名称",
        Message503: "请输入设备激活码",
        Message504: "未激活（剩余{msg}天）",
        Message505: "当前设备列表无数据",
        Message506: "设备列表",
        Message507: "该会议室名称已存在",
        Message508: "新增设备成功",
        Message509: "占用会议室数组",
        Message510: "所有会议室数组",
        Message511: "解绑成功",
        Message512: "新增激活码",
        Message513: "导出设备",
        Message514: "搜索名称",
        Message515: "无会议缺省图",
        Message516: "目前无会议，若要安排会议，请点击",
        Message517: "您确定要取消会议吗？",
        Message518: "已短信通知与会者",
        Message519: "取消后将短信发送通知与会者",
        Message520: "您确定要结束会议吗？",
        Message521: "当前会议进行中，可点击",
        Message522: "结束会议",
        Message523: "取消会议",
        Message524: "时间",
        Message525: "地点",
        Message526: "会议密码",
        Message527: "主讲人",
        Message528: "无密码",
        Message529: "暂未分配会议室",
        Message530: "今天",
        Message531: "最近七天",
        Message532: "最近三十天",
        Message533: "分值设定",
        Message534: "评分规则",
        Message535: "取平均值",
        Message536: "截尾平均",
        Message537: "点击添加选项按钮，可手动添加所需选项",
        Message538: "选项",

        Message539: "添加选项",
        Message540: "最大值最高为{max}，最小值最小为{min}",
        Message541: "编辑评分",
        Message542: "分值只能输入数字",
        Message543: "新增评分",
        Message544: "评分选项",
        Message545: "评分标题不能为空",
        Message546: "选项内容不能为空",
        Message547: "投票类型",
        Message548: "计票方式",
        Message549: "扫码投票",
        Message550: "可手动添加所需选项，",
        Message551: "投票标题必填，选项数量两个以上",
        Message552: "编辑投票",
        Message553: "投票选项",
        Message554: "投票选项不少于两个且标题必填",
        Message555: "新增投票",
        Message556: "投票",
        Message557: "评分",
        Message558: "导出结果",
        Message559: "评分结果",
        Message560: "分值",
        Message561: "评分项",
        Message562: "总分",
        Message563: "平均分",
        Message564: "最高分",
        Message565: "最低分",
        Message566: "评分人数",
        Message567: "平均值",
        Message568: "截尾平均值",
        Message569: "总参与人数：",
        Message570: "截尾平均数",
        Message571: "截尾平均分",
        Message572: "投票结果",
        Message573: "举手投票无投票结果记录",
        Message574: "当前投票为匿名投票，暂不支持查看",
        Message575: "投票名为：",
        Message576: "的投票为匿名投票，暂时不支持导出",
        Message577: "票",
        Message578: "投票名称：",
        Message579: "投票规则：",
        Message580: "投票结果：",
        Message581: "投票人名称",
        Message582: "投票人所选投票项",
        Message583: "无标题投票",
        Message584: "返回数据",
        Message585: "投票选项：",
        Message586: "投票人数：",
        Message587: "选项名称：",
        Message588: "{0}人投票",
        Message589: "该用户无姓名信息",
        Message590: "请输入会议名称",
        Message591: "预约",
        Message592: "选择日期",
        Message593: "请填写会议室",
        Message594: "选择时间",
        Message595: "会议类型",
        Message596: "普通会议",
        Message597: "视频会议",
        Message598: "主讲密码",
        Message599: "资料下载",
        Message600: "开启资料下载后，会后会议资料将允许普通用户下载",
        Message601: "文件加密",
        Message602: "开启加密，会议资料加密后只能用应用终端打开查看",
        Message603: "",
        Message604: "请填写会议名称",
        Message605: "会议名称长度限制为{msg}",
        Message606: "请检查密码是否符合规范",
        Message607: "这里这里",
        Message608: "账号注销",
        Message609: "注销帐号是不可恢复的操作。在注销帐号后，您将无法登录使用该帐号。",
        Message610: "您在注销帐号前，请确认：",
        Message611: "账号注销后，账号预约的会议数据会清除",
        Message612: "账号已完成的历史会议等数据属于企业，不会被清除。",
        Message613: "您的帐号中所包含的文件已得到妥善保存：包括但不限于会议录像等。",
        Message614: "确定注销",
        Message615: "身份验证",
        Message616: "为了您的账号安全，请先验证身份。注销账号后，将退出登录",
        Message617: "验证方式",
        Message618: "账号注销成功",
        Message619: "点击设置时间",
        Message620: "日期",
        Message621: "来宾密码",
        Message622: "立即创建",
        Message623: "预约会议",
        Message624: "会议名称为空，请输入会议名称",
        Message625: "请选择会议的开始日期",
        Message626: "请选择会议的开始时间",
        Message627: "如设置会议密码，密码长度必须为六位",
        Message628: "密码长度必须为六位",
        Message629: "会议创建成功",
        Message630: " 的会议",
        Message631: "点击确定完成预约{msg}或点击另外时间节点完成预约",
        Message632: "该时间段已被{msg}预约，或点击另外时间节点完成预约",
        Message633: "该时间段已有会议",
        Message634: "点击确定完成预约",
        Message635: "确定选择该时间段吗",
        Message636: "小时",
        Message637: "特别注意",
        Message638: "选择好时间段之后如果不点击小提示框的确定",
        Message639: "这次选中的时间不会生效",
        Message640: "选择预约时间只能选择最近七天",
        Message641: "同时预约多个会议室，只有最后一次预约的会议室会生效",
        Message642: "仅允许预约从当天开始一周内的时间，否则将会造成预约失败",
        Message643: "预定人",
        Message644: "是否录制",
        Message645: "名称:",
        Message646: "手机:",
        Message647: "邮箱:",
        Message648: "无邮箱",
        Message649: "账号:",
        Message650: "登录密码:",
        Message651: "所在单位:",
        Message652: "暂无企业",
        Message653: "前往创建单位",
        Message654: "前往待审核页面",
        Message655: "绑定手机",
        Message656: "暂无部门",
        Message657: "职位",
        Message658: "暂无职位",
        Message659: "暂无管理员信息",
        Message660: "参见",
        Message661: "用户名不能包含特殊字符",
        Message662: "退出单位说明",
        Message663: "退出单位是不可恢复操作，在退出单位后：",
        Message664: "系统会删除您帐号里的所有企业相关数据",
        Message665: "您的帐号则恢复为独立的个人用户帐号。",
        Message666: "退出单位成功",
        Message667: "请先同意退出单位说明",
        Message668: "职务",
        Message669: "角色类型",
        Message670: "主持人",
        Message671: "与会者",
        Message672: "临时人员",
        Message673: "入会时间",
        Message674: "按部门选择",
        Message675: "用户列表",
        Message676: "选中列表",
        Message677: "请选择与会者",
        Message678: "添加成功",
        Message679: "添加失败",
        Message680: "文件模板",
        Message681: "点击下载模板",
        Message682: "文件导入",
        Message683: "点击导入文件",
        Message684: "临时人员导入模板",
        Message685: "请上传符合格式的文件",
        Message686: "发送会议通知",
        Message687: "添加与会者",
        Message688: "添加临时人员",
        Message689: "导入临时人员",
        Message690: "导出与会者列表",
        Message691: "搜索与会者姓名",
        Message692: "会议通知",
        Message693: "编辑临时人员",
        Message694: "您确定要删除所选人员吗",
        Message695: "与会者列表",
        Message696: "短信模板",
        Message697: "模板内容",
        Message698: "全选",
        Message699: "发送成功",
        Message700: "发送失败",
        Message701: "已存在该手机号",
        Message702: "手机号已存在",
        Message703: "导出签到列表",
        Message704: "搜索手机号",
        Message705: "暂无可导出的数据",
        Message706: "会议描述",
        Message707: "暂无描述",
        Message708: "签到时间",
        Message709: "签到列表",
        Message710: "至",
        Message711: "您确定要删除录制文件吗",
        Message712: "获取文件信息成功",
        Message713: "程序类型",
        Message714: "升级器",
        Message715: "版本号",
        Message716: "请输入版本号",
        Message717: "版本说明",
        Message718: "请填写版本信息",
        Message719: "请填写更新信息",
        Message720: "版本文件",
        Message721: "修改失败",
        Message722: "可尝试重新上传",
        Message723: "当前仅支持",
        Message724: "文件格式",
        Message725: "可上传相关的客户端文件或升级器文件，仅允许{0}格式，请确保上传正确格式",
        Message726: "请上传版本文件",
        Message727: "只允许上传一个文件",
        Message728: "删除文件出错",
        Message729: "搜索关键词",
        Message730: "类型",
        Message731: "更新内容描述",
        Message732: "查看升级内容",
        Message733: "启用",
        Message734: "启用中",
        Message735: "停用",
        Message736: "确定要删除该项吗",
        Message737: "确定要删除这些项吗",
        Message738: "此操作将禁用版本文件",
        Message739: "禁用成功",
        Message740: "已取消禁用",
        Message741: "此操作将修改版本文件状态",
        Message742: "已取消修改",
        Message743: "手机",
        Message744: "密码为{0}位字母数字或字符，默认密码为",
        Message745: "搜索姓名",
        Message746: "批量同意",
        Message747: "批量拒绝",
        Message748: "用户类型",
        Message749: "申请时间",
        Message750: "已拒绝",
        Message751: "已同意",
        Message752: "同意",
        Message753: "拒绝",
        Message754: "请选择需要同意的项",
        Message755: "请选择需要拒绝的项",
        Message756: "确定要拒绝吗",
        Message757: "确定要同意吗",
        Message758: "确定要删除吗",
        Message759: "已审核",
        Message760: "未审核",
        Message761: "暂无可同意项",
        Message762: "同意申请成功",
        Message763: "暂无可拒绝项",
        Message764: "拒绝申请成功",
        Message765: "是否覆盖如下手机号",
        Message766: "覆盖",
        Message767: "用户导入模板",
        Message768: "第{msg}位用户姓名或电话填写有误，请检查",
        Message769: "数据格式可能有误，请检查数据格式",
        Message770: "手机号重复",
        Message771: "用户导入失败",
        Message772: "覆盖成功",
        Message773: "分享单位链接邀请同时加入单位",
        Message774: "邀请链接",
        Message775: "复制链接",
        Message776: "成员加入是否需要审核：",
        Message777: "审核",
        Message778: "不审核",
        Message779: "生成新链接",
        Message780: "复制成功",
        Message781: "成员审核",
        Message782: "新增人员",
        Message783: "导入人员",
        Message784: "邀请",
        Message785: "导出人员",
        Message786: "数据处理中",
        Message787: "用户状态",
        Message788: "移除",
        Message789: "确定要删除用户吗？",
        Message790: "将注销这些用户的账号，同时账号所预约的会议数据将会清除；",
        Message791: "已产生的历史数据属于企业，不会被清除",
        Message792: "确定要停用该用户吗？停用后，账号将不可用",
        Message793: "确定要启用该用户吗？",
        Message794: "确定要重置密码吗",
        Message795: "密码将会被重置为默认密码",
        Message796: "编辑用户",
        Message797: "导入用户",
        Message798: "邀请加入单位",
        Message799: "密码已重置为默认密码，请及时通知该成员",
        Message800: "不允许删除管理员，自动为您过滤",
        Message801: "会议云盘",
        Message802: "已完成",
        Message803: "上传中",
        Message804: "出错",
        Message805: "视频会议中主席密码默认设置为“1”",
        Message806: "取消文件上传",
        Message807: "程序版本",
        Message808: "常规版",
        Message809: "国产版",
        Message810: "文件名称不得超过{num}个字符",
        Message811: "文件大小不得超过{msg}",
        Message812: "本次文件上传数量超过{num}",
        Message813: "会议室",
        Message814: "搜索会议名称/会议号",
        Message815: "搜索名称/会议室/设备型号",
        Message816: "快速会议",
        Message817: "保密会议",
        Message818: "单位",
        Message819: "表决",
        Message820: "满意度测评",
        Message821: "请输入投票标题",
        Message822: "获取文件路径失败",
        Message823: "文件来源",
        Message824: "搜索会议名称/预定人/会议号",
        Message825: "请求超时，最大时长为 1 小时",
        Message826: "请输入评分标题",
        Message827: "在评分人数不足3人时，将自动使用平均数统计分值",
        Message828: "赞成",
        Message829: "反对",
        Message830: "弃权",
        Message831: "满意",
        Message832: "基本满意",
        Message833: "不满意",
        Message834: "分享单位链接邀请同事加入单位",
        Message835: "成员加入是否需要管理员审核：",
        Message836: "终端序列号",
        Message837: "设备数量(个)",
        Message838: "用户账号(个)",
        Message839: "会议室(个)",
        Message840: "该视频编码格式或码率暂不支持在线播放，请下载后查看",
        Message841: "开启文件加密，会中资料不可保存到客户端本地，不可分享，会后资料不可在平台查看，只可解密下载",
        Message842: "添加会议室",
        Message843: "请",
        Message844: "设备管理",
        Message845: "个",
        Message846: "人",
        Message847: "分",
        Message848: "次",
        Message849: "搜索姓名/手机号",
        Message850: "示意：开启文件加密，会中资料不可保存到客户端本地，不可分享，会后资料不可在平台查看，只可解密下载",
        Message851: "加密（会议资料只能在ihub终端打开）",
        Message852: "示意：开启资料下载，会后会议资料可查看下载",
        Message853: "单位ID：",
        Message854: "地址长度不允许超过50字符",
        Message855: "修改失败,可尝试重新上传",
        Message856: "当前仅支持zip文件格式",
        Message857: "解散单位是不可恢复的操作。在解散单位后，所有成员都成为独立的个人用户账号。",
        Message858: "此操作将覆盖原议程文件, 是否继续？",
        Message859: "姓名/模块名称/操作类型",
        Message860: "此操作将禁用版本文件, 是否继续？",
        Message861: "此操作将修改版本文件状态, 是否继续？",
        Message862: "人投票",
        Message863: "搜索手机号/姓名",
        Message864: "密码为8-16位字母数字或字符，默认密码为12345678",
        Message865: "搜索与会者姓名/手机号",
        Message866: "可上传相关的客户端文件或升级器文件，仅允许zip格式，请确保上传正确格式",
        Message867: "点击确定完成预约或点击另外时间节点完成预约",
        Message868: "，或点击另外时间节点完成预约",
        Message869: "该视频编码格式或码率不支持在线播放，请下载后查看",
        Message870: "点击设置日期",
        Message871: "点击设置会议室",
        Message872: "高级搜索",
        Message873: "选项内容",
        Message874: "用户数据",
        Message875: "选项列表",
        Message876: "复制",
        Message877: "复制成功",
        Message878: "请输入邮箱",
        Message879: "请输入手机号/邮箱",
        Message880: "该邮箱已经注册过，您可直接登录",
        Message881: "手机号/邮箱不能为空",
        Message882: "注册手机",
        Message883: "注册邮箱",
        Message884: "验证码已发送到邮箱",
        Message885: "开机",
        Message886: "关机",
        Message887: "确认要开启该设备吗？",
        Message888: "确认要关闭该设备吗？",
        Message889: "确认要开启所选中的设备吗？",
        Message890: "确认要关闭所选中的设备吗？",
        Message891: "批量开机",
        Message892: "批量关机",
        Message893: "请选择设备",
    },
    add: {
        mes1: "当前会议为",
        mes2: "预订",
        meetingDetails: "会议详情",
    },
};